<template>
  <div>
    <v-card-title>
      {{ $route.params.uid ? '멘토 정보 수정' : '멘토 신규 등록' }}
      <v-spacer />
      <div class="text-right">
        <v-btn rounded dark @click="submit">
          {{ $route.params.uid ? '수정하기' : '가입하기' }}
        </v-btn>
      </div>
    </v-card-title>
    <v-card outlined flat>
      <v-list-item>
        <v-checkbox v-model="visible" label="프론트 노출 여부" hide-details />
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>이름</v-list-item-title>
          <v-list-item-subtitle>
            앱에서 노출될 이름을 적어주세요.
          </v-list-item-subtitle>
          <v-text-field v-model="name" outlined dense hide-details placeholder="예) 도르체" />
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>유저 id</v-list-item-title>
          <v-list-item-subtitle>
            유저 id를 저장해야 댓글에서 멘토표시가 노출됩니다.
          </v-list-item-subtitle>
          <UserInput v-model="user" />
          <v-btn depressed color="#ffd600" class="ml-3" light @click="addUser">
            추가
          </v-btn>
          <div v-for="(item, index) in User" :key="index" class="mt-2">
            {{ index + 1 }}. {{ item.name }}
            <v-btn class="ml-3 mb-1" rounded small light color="#ffd600" @click="User.splice(index, 1)" v-text="'삭제'" />
          </div>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>프로필이미지</v-list-item-title>
          <v-list-item-subtitle>
            멘토 정보에서 노출될 이미지를 등록해주세요
          </v-list-item-subtitle>
          <div>
            <v-card v-if="profileLink" max-width="300" flat>
              <v-img height="100%" :src="profileLink" />
            </v-card>
          </div>
          <v-file-input v-model="profileImage" dense outlined hide-details prepend-inner-icon="mdi-file-image" :prepend-icon="null" />
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>학교</v-list-item-title>
          <v-list-item-subtitle>
            멘토 정보에서 노출될 학교를 적어주세요
          </v-list-item-subtitle>
          <v-text-field v-model="school" outlined dense hide-details placeholder="예) 서울대학교" />
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>전공</v-list-item-title>
          <v-list-item-subtitle>
            멘토 정보에서 노출될 전공을 적어주세요
          </v-list-item-subtitle>
          <v-text-field v-model="major" outlined dense hide-details placeholder="예) 정보통신전자공학부" />
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>중학교</v-list-item-title>
          <v-list-item-subtitle>
            중학교 분류를 선택해주세요
          </v-list-item-subtitle>
          <v-select v-model="middleSchoolKind" :items="middleSchoolKinds" outlined dense hide-details placeholder="중학교 분류를 선택해주세요" />
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>고등학교</v-list-item-title>
          <v-list-item-subtitle>
            고등학교 분류를 선택해주세요
          </v-list-item-subtitle>
          <v-select v-model="highSchoolKind" :items="highSchoolKinds" outlined dense hide-details placeholder="고등학교 분류를 선택해주세요" />
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>학딩이들에게 한마디</v-list-item-title>
          <v-list-item-subtitle>
            학딩이들에게 해주고 싶은 말 한 마디를 적어주세요
          </v-list-item-subtitle>
          <v-text-field v-model="introductionTitle" outlined dense hide-details placeholder="학딩이들에게 하고 싶은 말을 짧게 적어주세요!" />
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>자기소개</v-list-item-title>
          <v-list-item-subtitle>
            멘토 정보에서 노출될 자기소개 문구를 적어주세요
          </v-list-item-subtitle>
          <v-textarea v-model="introduction" outlined dense hide-details placeholder="인사말, 대학 합격 내용 등 다양한 문구를 구어체로 적어주세요!" />
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>특성</v-list-item-title>
          <v-list-item-subtitle>
            공부관련 특성을 선택해주세요
          </v-list-item-subtitle>
          <v-select v-model="abilityId" :items="abilities" outlined dense hide-details placeholder="특성을 선택해주세요" />
        </v-list-item-content>
      </v-list-item>
    </v-card>
  </div>
</template>

<script>
import { defineComponent, onBeforeMount, reactive, toRefs } from '@vue/composition-api'
import UserInput from '@/components/input/User.vue'

export default defineComponent({
  components: {
    UserInput,
  },

  setup(props, { root }) {
    const state = reactive({
      userId: null,
      visible: true,
      name: null,
      school: null,
      major: null,
      profileLink: null,
      profileImage: null,
      introduction: null,
      dialog: false,
      abilities: [],
      middleSchoolKinds: [],
      middleSchoolKind: null,
      highSchoolKinds: [],
      highSchoolKind: null,
      abilityId: null,
      introductionTitle: '',
      user: null,
      User: [],
    })

    const init = async () => {
      await root.$store.dispatch('app/getListAbility')
      await root.$store.dispatch('user/getMiddleSchoolKind')
      await root.$store.dispatch('user/getHighSchoolKind')
      state.education = 'mentor'

      state.abilities = root.$store.getters['app/abilities'].map((ability) => ({
        text: ability.name,
        value: ability.id,
      }))
      state.middleSchoolKinds = root.$store.getters['user/middleSchoolKind'].map((middleSchoolKind) => ({
        text: middleSchoolKind.name,
        value: middleSchoolKind.name,
      }))
      state.highSchoolKinds = root.$store.getters['user/highSchoolKind'].map((highSchoolKind) => ({
        text: highSchoolKind.name,
        value: highSchoolKind.name,
      }))

      if (root.$route.params.uid) {
        const res = await root.$store.dispatch('mentor/getOneMentor', {
          id: Number(root.$route.params.uid),
        })

        state.visible = res.visible
        state.name = res.name
        state.school = res.school
        state.major = res.major
        state.introductionTitle = res.introductionTitle
        state.introduction = res.introduction
        state.abilityId = res.ability[0] ? res.ability[0].id : null
        state.profileLink = res.profileLink
        state.User = [...res.User]
        if (res.mentorSchoolInfo) {
          state.middleSchoolKind = res.mentorSchoolInfo.middleSchoolKind
          state.highSchoolKind = res.mentorSchoolInfo.highSchoolKind
        }
      }
    }

    const addUser = () => {
      try {
        if (state.User.find((e) => e.id === state.user.id)) {
          state.user = null
          return
        }

        state.User.push(state.user)
        state.user = null
      } catch (err) {
        console.error(err)
        alert(err)
      }
    }

    const submit = async () => {
      try {
        if (root.$route.params.uid) {
          await root.$store.dispatch('mentor/updateMentor', {
            mentorId: Number(root.$route.params.uid),
            userId: state.User.map((e) => Number(e.id)),
            visible: state.visible,
            name: state.name,
            school: state.school,
            major: state.major,
            profileImage: state.profileImage,
            introduction: state.introduction,
            abilityId: Number(state.abilityId),
            middleSchoolKind: state.middleSchoolKind,
            highSchoolKind: state.highSchoolKind,
            introductionTitle: state.introductionTitle,
          })
        } else {
          await root.$store.dispatch('mentor/createMentor', {
            userId: state.User.map((e) => Number(e.id)),
            visible: state.visible,
            name: state.name,
            school: state.school,
            major: state.major,
            profileImage: state.profileImage,
            introduction: state.introduction,
            abilityId: Number(state.abilityId),
            middleSchoolKind: state.middleSchoolKind,
            highSchoolKind: state.highSchoolKind,
            introductionTitle: state.introductionTitle,
          })
        }

        alert('완료!')
        root.$router.push('/mentor')
      } catch (err) {
        console.error(err)
        alert(err)
      }
    }

    onBeforeMount(() => {
      init()
    })

    return {
      ...toRefs(state),
      addUser,
      submit,
    }
  },
})
</script>
